body {
    background: rgb(0, 0, 0);

}

.buffer {
    height: 100px;
}

.icons {
    margin-left: 10px;
    margin-right: 10px;
    animation-duration: 20s;
}